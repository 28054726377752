import Vue from 'vue';

export const vScroll = {
  bind(el, binding) {
    const helper = {
      tm(evt) {
        if (el.offsetHeight < el.scrollHeight) {
          evt._isScroller = true;
        }
      },
      ts(evt) {
        const top = el.scrollTop;
        const totalScroll = el.scrollHeight;
        const currentScroll = top + el.offsetHeight;

        // If we're at the top or the bottom of the containers
        // scroll, push up or down one pixel.
        //
        // this prevents the scroll from "passing through" to
        // the body.
        if (top === 0) {
          el.scrollTop = 1;
        } else if (currentScroll === totalScroll) {
          el.scrollTop = top - 1;
        }
      },
    };

    el.helper = helper;

    el.addEventListener('touchmove', helper.tm);
    el.addEventListener('touchstart', helper.ts);
  },
  unbind(el, binding) {
    const helper = el.helper;
    if (helper) {
      el.removeEventListener('touchmove', helper.tm);
      el.removeEventListener('touchstart', helper.ts);
    }
  },
};

export default {
  install(vue, options) {
    Vue.directive('scroll', vScroll);
  },
};
