import Vuex, { mapMutations } from 'vuex';

const { mapState, mapGetters, mapActions } = Vuex;

export const projectComputed = {
  ...mapState('project', {}),
  ...mapGetters('project', ['getGames', 'getProjectId', 'getProjectList']),
};

export const projectMethods = {
  ...mapMutations('project', { setProjectId: 'SET_PROJECT_ID' }),
  ...mapActions('project', ['fetchProject']),
};
