import { dataMethods, languageMethods, projectComputed, resultsComputed, resultsMethods } from '@state/helpers.js';
import template from './init-app.html';

export default {
  template,
  methods: {
    ...languageMethods,
    ...resultsMethods,
    ...dataMethods,
  },
  computed: { ...projectComputed, ...resultsComputed },
  async mounted() {
    this.syncCount = 0;

    this.unsubscribe = this.$store.subscribeAction({
      after: async ({ type }, state) => {
        switch (type) {
          case 'data/setStatusUp':
            {
              if (!this.haveData) {
                // console.log('status up');
                const current = this.currentResults;
                if (current && current.state !== 'aborted') {
                  if (current.state !== 'prestart' && current.state !== 'pending') {
                    this.setState('abandoned');
                  }
                  await this.save({ token: 'wiffle', value: current });
                  await this.clearResults();
                }
                this.syncCount |= 4;
              }
            }
            break;
          case 'project/fetchProject':
            this.syncCount |= 1;

            break;
          case 'translator/initState':
            this.syncCount |= 2;

            break;
        }

        if (this.syncCount == 7) {
          // all done so unsubscribe from other notifications
          this.unsubscribe();
          // await this.$store.dispatch('profile/fetchProfile');
          this.$emit('init-app', { type: 'fetchProject' });
        }
      },
    });

    this.initState();
  },
};
