import { locale as setLocale, set, t, fallback, onMissingKey } from 'frenchkiss';
import api from '@state/api.js';

export default {
  namespaced: true,
  state() {
    return { languageTranslator: {}, locale: '', locales: [] };
  },
  mutations: {
    setTranslator(state, language) {
      state.languageTranslator = (key, params) => t(key, params, language);
      state.locale = language;
    },
    setLocales(state, newValue) {
      state.locales = newValue;
    },
  },
  getters: {
    t(state) {
      return (key, params) => state.languageTranslator(key, params);
    },
    getLocales(state) {
      return state.locales;
    },
  },
  actions: {
    async initState({ state, commit }) {
      const locales = [];
      let languages;

      try {
        languages = await api.get(`/api/languages`).json();
      } catch (e) {
        languages = [];
      }

      languages.forEach(({ locale, table }) => {
        locales.push(locale);
        set(locale, table);
      });

      const browserLanguage = navigator.language.split('-')[0];
      const locale = locales.indexOf(browserLanguage) != -1 ? browserLanguage : 'en';
      setLocale(locale);
      fallback('en');

      onMissingKey((key, params, locale) => `[${locale}: ${key}]`);

      commit('setLocales', locales);
      commit('setTranslator', locale);
    },
  },
};
