import actions from './actions.js';

export default {
  namespaced: true,
  state: {
    count: 0,
    status: 'up',
    uploadUrl: '',
  },
  mutations: {
    SET_COUNT(state, newValue) {
      state.count = newValue;
    },
    INCREMENT_COUNT(state, n = 1) {
      state.count -= n;
      if (state.count < 0) {
        state.count = 0;
      }
    },

    SET_STATUS(state, newValue) {
      state.status = newValue;
    },
    SET_UPLOAD_URL(state, newValue) {
      state.uploadUrl = newValue;
    },
  },
  actions,
};

// ===
// Private functions
// ===
