export default {
  user: { can: [] },
  editor: {
    can: ['projects:edit'],
    inherits: ['user'],
  },
  admin: {
    can: ['globalusers:admin', 'wordsets:edit', 'admin:edit'],
    inherits: ['editor'],
  },
  system: {
    can: ['system:manage'],
    inherits: ['admin'],
  },
};
