export default {
  async load(url, name = 'default') {
    const m = await import(/* webpackChunkName: "[request]" */ `~/${url}`);
    const component = m[name];
    if (!component.name) {
      component.name = baseName(url);
    }
    return Object.create(component);
  },

  async loadRaw(url) {
    return await import(`~/${url}`);
  },
};

// ===
// Private functions
// ===

function baseName(str) {
  let base = str.substring(str.lastIndexOf('/') + 1);
  if (base.lastIndexOf('.') != -1) {
    base = base.substring(0, base.lastIndexOf('.'));
  }
  return base;
}
