import ky, { HTTPError } from 'ky';

const kyi = setInstance();

const defaults = {
  headers: {
    common: {
      Accept: 'application/json, text/plain, */*',
    },
  },
  beforeFetch: null,
};

export default kyi;

export { defaults, HTTPError };

function setInstance() {
  return ky.extend({
    hooks: {
      beforeRequest: [
        async (request, options) => {
          if (defaults.beforeFetch) {
            return await defaults.beforeFetch(request, options);
          }
        },
        (request, options) => {
          // merge header if available
          [...Object.entries(defaults.headers.common)].forEach(
            ([key, value]) => !request.headers.has(key) && request.headers.set(key, value),
          );
        },
      ],
      afterResponse: [
        // Or retry with a fresh token on a 403 error
        async (request, options, response) => {
          // HTTP2 doesn't support the reason phrase
          // so if a validation error, ensure the status text carries the message
          // then the error when caught will have the message property filled in.
          if (response.status === 422) {
            const message = await response.text();

            return new Response(null, { status: 422, statusText: message });
          }
        },
      ],
    },
  });
}
