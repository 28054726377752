import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules/index.js';

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: true, // This makes the Vuex store strict
  state: {
    isOpen: 0,
    snackbarHandler: null,
    panelId: '',
  },
  mutations: {
    open(state) {
      state.isOpen = true;
    },
    close(state) {
      state.isOpen = false;
    },
    setSnackbarHandler(state, handler) {
      state.snackbarHandler = handler;
    },
    setPanelId(state, newValue) {
      state.panelId = newValue;
    },
  },
  getters: {
    panelId(state) {
      return state.panelId;
    },
  },
  actions: {
    showSnack({ state, rootGetters }, snack) {
      const t = rootGetters['translator/t'];
      const locale = rootGetters['translator/locale'];

      if (snack.command == 'reload') {
        snack.message = locale ? t('app.snackbar.reload-message') : 'New version available';
        snack.actionText = locale ? t('app.snackbar.reload-text') : 'Reload';
      }
      if (state.snackbarHandler) {
        state.snackbarHandler(snack);
      }
    },
  },
  modules,
});

// Automatically run the `init` action for every module,
// if one exists.
Object.keys(modules).reduce(async (p, moduleName) => {
  await p;
  if (modules[moduleName].actions.init) {
    p = store.dispatch(`${moduleName}/init`);
  }

  return p;
}, Promise.resolve());
export default store;
