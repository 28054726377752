import { openDB } from 'idb';

// note: this version should be incremented if stores are added/removed from the database.
const dbVersion_ = 1;
const stores_ = ['queue', 'profile'];

function createObjectStores(db, storeNames) {
  storeNames.forEach(storeName => {
    if (!db.objectStoreNames.contains(storeName)) {
      db.createObjectStore(storeName);
    }
  });
}

const dbPromise_ = openDB('dbstore', dbVersion_, {
  upgrade(db) {
    createObjectStores(db, stores_);
  },
  blocked() {
    console.log('Database blocked: Please close all other tabs with this site open.');
  },
  blocking() {
    console.log('db version change detected Jim...');
  },
});

class Store {
  constructor(storeName, dbPromise = dbPromise_) {
    this.storeName = storeName;
    this.dbPromise = dbPromise;
  }

  async get(key) {
    return (await this.dbPromise).get(this.storeName, key);
  }
  async set(key, val) {
    return (await this.dbPromise).put(this.storeName, val, key);
  }
  async delete(key) {
    return (await this.dbPromise).delete(this.storeName, key);
  }
  async clear() {
    return (await this.dbPromise).clear(this.storeName);
  }
  async keys() {
    return (await this.dbPromise).getAllKeys(this.storeName);
  }
}

export { Store };
