import loader from '../loader.js';
import store from '@state/store.js';

export default [
  {
    path: '/login/reset-password',
    component: () => loader.load('login/reset.js'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => loader.load('login/login.js'),
  },
  {
    path: '/login/sendreset',
    name: 'sendreset',
    component: () => loader.load('login/sendreset.js'),
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
    },
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch('auth/logOut');
      const authRequiredOnPreviousRoute = routeFrom.matched.some(route => route.meta.authRequired || true);
      // Navigate back to previous page, or home as a fallback
      next(authRequiredOnPreviousRoute ? { name: 'login' } : { ...routeFrom });
    },
  },
  {
    path: '/details',
    name: 'mydetails',
    meta: {
      authRequired: true,
    },

    component: () => loader.load('general/my-details.js'),
  },
  {
    path: '/pwd',
    name: 'changepassword',
    meta: {
      authRequired: true,
    },

    component: () => loader.load('general/change-password.js'),
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      authRequired: true,
    },
    component: () => loader.load('home/home.js'),
  },
  {
    path: '/profiles',
    name: 'profiles',
    meta: {
      authRequired: true,
    },
    component: () => loader.load('profile/profiles.js'),
  },
  {
    path: '/profile/:id',
    name: 'profile',
    meta: {
      authRequired: true,
    },
    component: () => loader.load('profile/profile.js'),
  },
  {
    path: '/profile/:id/:taskId',
    name: 'profiletask',
    meta: {
      authRequired: true,
    },
    component: () => loader.load('profile/profile-task.js'),
  },

  {
    meta: {
      authRequired: true,
    },
    path: '/tasks/boundary/:taskId',
    name: 'boundary-task',
    props: true,
    component: () => loader.load('tasks/boundary/index.js'),
  },
  {
    meta: {
      authRequired: true,
    },
    path: '/tasks/oddity/:taskId',
    name: 'oddity-task',
    props: true,
    component: () => loader.load('tasks/oddity/index.js'),
  },
  // {
  //   meta: {
  //     authRequired: true,
  //   },
  //   path: '/client/tasks/mts',
  //   name: 'mts-task',
  //   component: () => loader.load('tasks/mts/mts.js'),
  // },
  // {
  //   meta: {
  //     authRequired: true,
  //   },
  //   path: '/client/tasks/conjunction',
  //   name: 'conjunction-task',
  //   component: () => loader.load('tasks/conjunction/conjunction.js'),
  // },
  {
    meta: {
      authRequired: true,
    },
    path: '/tasks/htunl/:taskId',
    name: 'htunl-task',
    props: true,
    component: () => loader.load('tasks/htunl/index.js'),
  },
  {
    path: '/help',
    name: 'help',
    component: () => loader.load('help/help.js'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => loader.load('about/about.js'),
  },
  { path: '/:remainder*', redirect: { name: 'home' } },
  { path: '/app/:remainder*', redirect: { name: 'home' } },
];
