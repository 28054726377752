/* global __SWJS__ */
async function init(store) {
  if ('serviceWorker' in navigator) {
    const { Workbox } = await import('workbox-window');

    const wb = new Workbox(__SWJS__);

    const actionHandler = () => {
      wb.addEventListener('controlling', event => {
        window.location.reload();
      });
      wb.messageSW({ type: 'SKIP_WAITING' });
    };

    wb.addEventListener('activated', event => {
      if (!event.isUpdate) {
        wb.messageSW({ type: 'CLIENTS_CLAIM' });
      }
    });

    wb.addEventListener('waiting', event => {
      store.dispatch('showSnack', {
        message: 'A new version of this app is available.',
        actionText: 'Refresh',
        dismissAction: true,
        leading: true,
        actionHandler,
      });
    });

    wb.register();
  }
}
export default init;
