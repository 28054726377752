import Vuex, { mapMutations } from 'vuex';

const { mapState, mapGetters, mapActions } = Vuex;
export const authComputed = {
  ...mapState('auth', {
    currentUser: state => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn', 'myRole', 'can']),
};

export const authMethods = mapActions('auth', ['logIn', 'logOut', 'requestResetCode', 'loginWithCode']);
export const rbacComputed = {
  ...mapGetters('rbac', ['rbacCan']),
};
export const dataComputed = {
  ...mapState('data', {
    count: state => state.count,
    status: state => state.status,
  }),
};

export const dataMethods = mapActions('data', ['save']);

export const resultsComputed = {
  ...mapState('results', {
    currentResults: state => state.currentResults,
  }),
};

export const resultsMethods = mapActions('results', ['createResults', 'clearResults', 'setState', 'pushItem']);

export const taskComputed = {
  ...mapGetters(['panelId']),
};

export const languageComputed = {
  ...mapGetters('translator', ['t', 'getLocales']),
  ...mapState('translator', { getLocale: state => state.locale }),
};
export const languageMethods = {
  ...mapActions('translator', ['initState']),
  ...mapMutations('translator', ['setTranslator']),
};
