let Vue_;

function install(vue) {
  if (Vue_ && Vue_ === vue) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(
        '[vueerr] already installed. Vue.use(vueerr) should be called only once.',
      );
    }
    return;
  }
  Vue_ = vue;
  configError(vue);
}

export default { install };

// ===
// Private functions
// ===

function formatComponentName(vm) {
  if (vm.$root === vm) {
    return 'root instance';
  }
  const name = vm._isVue
    ? vm.$options.name || vm.$options._componentTag
    : vm.name;
  return (
    (name ? `component <${name}>` : 'anonymous component') +
    (vm._isVue && vm.$options.__file ? ` at ${vm.$options.__file}` : '')
  );
}

function configError(vue) {
  const oldOnError = vue.config.errorHandler;
  vue.config.errorHandler = function VueErrorHandler(error, vm) {
    const extra = {
      componentName: formatComponentName(vm),
      propsData: vm.$options.propsData,
    };

    console.dir(extra);
    console.dir(error.toString());

    if (typeof _oldOnError === 'function') {
      oldOnError.call(this, error, vm);
    }
  };
}
