import { Store } from '../../idb-db.js';

let store_;

function setupDb() {
  store_ = new Store('queue');
  return store_;
}

function db() {
  return store_;
}

export { db as dataDb, setupDb };
