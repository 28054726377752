import api from '@state/api.js';
import timestring from 'timestring';

export default {
  namespaced: true,
  state: {
    currentProjectId: null,

    projects: [],
  },
  mutations: {
    SET_PROJECT_MAP(state, newValue) {
      state.projects = newValue;
    },

    SET_PROJECT_ID(state, newValue) {
      state.currentProjectId = newValue;
    },

    SET_GAMES(state, newValue) {
      state.games = newValue;
    },
  },
  getters: {
    taskDurations(state, getters, rootState, rootGetters) {
      const id = rootGetters['session/userTask'];
      return state.currentProject[id];
    },
    getGames(state) {
      const project = state.projects.get(state.currentProjectId);

      return project ? project.games : new Map();
    },
    getProjectId(state) {
      return state.currentProjectId;
    },
    getProjectList(state) {
      return [...state.projects.values()];
    },
  },
  actions: {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    async init({ state, dispatch, rootGetters }) {
      const userId = rootGetters['auth/userId'];
      if (userId) {
        const { id } = getSavedState(`lastProject-${userId}`) || { id: null };
        state.lastProjectId = id;
      }
      await dispatch('fetchProject');
    },

    // note this name is singular to match name in init-app (which is shared with other projects where poss.)
    async fetchProject({ state, commit, rootGetters }) {
      let projects;

      const userId = rootGetters['auth/userId'];
      if (userId) {
        try {
          projects = await api.get(`/api/project`).json();
        } catch (e) {
          projects = [];
        }

        const projectMap = new Map(
          projects.map(p => {
            const { games, ...project } = p;
            project.games = new Map(games.map(i => [i._id, i]));
            return [project._id, project];
          }),
        );

        commit('SET_PROJECT_MAP', projectMap);
      }
    },
  },
};

// ===
// Private helpers
// ===

const fields = [
  ['session.minDuration', 'min'],
  ['sentence.feedback', 'ms'],
  ['sentence.pause', 'ms'],
  'sentence.timeout',
  ['sentence.cue', 'ms'],
  ['sentence.firstCue', 'ms'],
  ['phoneme.feedback', 'ms'],
  ['phoneme.pause', 'ms'],
  'phoneme.timeout',
  ['phoneme.ISI', 'ms'],
];
// eslint-disable-next-line no-unused-vars
function fixupDurations(project) {
  fields.forEach(item => {
    let units = 's';
    if (Array.isArray(item)) {
      [item, units] = item;
    }
    item.split(/[.[\]]+/).reduce((prev, key, index, array) => {
      if (array.length === index + 1) {
        prev[key] = getDuration(prev[key], units);
      }

      return prev[key];
    }, project);
  });
}

function getDuration(value, units) {
  value = String(value);
  const groups = value
    .toLowerCase()
    .replace(/[^.\w+-]+/g, '')
    .match(/[-+]?[0-9.]+[a-z]+/g);
  if (!groups) {
    value = `${value}${units}`;
  }

  return timestring(value, 'ms');
}

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

// function saveState(key, state) {
//   window.localStorage.setItem(key, JSON.stringify(state));
// }
